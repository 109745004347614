<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="table-box p-3 mb-3">
                            <div
                                class="table-title d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column mb-3">
                                <div class="flex-grow-1 my-auto">
                                    <h2 class="mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3 pb-2">
                                        Current Content
                                    </h2>
                                </div>
                            </div>
                            <div class="content-aria" v-html="dataList.formData.description"></div>
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="table-box p-3 mb-3">
                            <div
                                class="table-title d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column mb-3">
                                <div class="flex-grow-1 my-auto">
                                    <h2 class="mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3 pb-2">
                                        Old Content
                                    </h2>
                                </div>
                            </div>
                            <div v-if="dataList.formData.version"
                                 class="content-aria"
                                 v-html="dataList.formData.version.description">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HelperFunction from "@/common/helpers";
import {useStore} from "vuex";
import {reactive, watch} from "vue";
import {useRoute} from "vue-router";

export default {
    name: "ExternalDocumentHistory",
    props: {
        id: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const {getAllData} = HelperFunction();
        let dataList = reactive({
            formData: {
                description: '',
                version: {}
            }
        })
        watch(
            () => props.id,
            (value) => {
                if (value) {
                    getAllData(`${vueConfig.Library.ExternalDocumentsEndPoint}/${value}`)
                        .then(response => {
                            let data = response.data.data;
                            if (data) dataList.formData = {...dataList.formData, ...data};
                        })
                }
            },
            {immediate: true}
        );

        return {
            dataList
        }
    }
}
</script>

<style scoped>
.content-aria {
    width: 100%;
    overflow: auto;
    max-height: 700px;
}
</style>

